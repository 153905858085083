import { ServiceLoader } from '@sgme/ui';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import type { Store } from 'redux';

import { CrashModal } from 'components/Modals';
import { IntlProvider, langKey } from 'components/contexts/IntlContext';

import type { AppState } from 'state/model';
import { App } from './components/App';
import { initSgBootstrap } from './utils/theme';

import './main.scss';

import 'react-datepicker/dist/react-datepicker.css';

import { isDefined } from '@sgme/fp';
import { SgwtWebAnalytics, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import en from 'components/share/locales/en.json';
import fr from 'components/share/locales/fr.json';
import { getConfig } from 'config/config';
import { postApi } from './api/fetch';

const globalMessagesMap = { en, fr };

initSgBootstrap();

function getLang() {
  const lang = localStorage.getItem(langKey);
  // Revert lang to english for users who had selected german
  if (lang === 'de') {
    localStorage.setItem(langKey, 'en');
    return 'en';
  }
  return lang ?? 'en';
}

const lang = getLang();

setTimeout(() => {
  document.querySelector('html')!.lang = lang;
});

export function renderReactApp(store: Store<AppState>) {
  const container = document.getElementById('main');
  const root = createRoot(container!);

  root.render(<AppContainer store={store} />);
}

type AppContainerProps = {
  store: Store;
};

const AppContainer = (props: AppContainerProps) => {
  const { store } = props;

  // log when the user opens a new tab, refreshes it or closes it
  useEffect(() => {
    const sessionId = getSessionId();

    postApi(`api/user/${sessionId}/connect`);

    const disconnect = () => {
      postApi(`api/user/${sessionId}/disconnect`);
    };

    window.addEventListener('beforeunload', disconnect);

    return () => {
      window.removeEventListener('beforeunload', disconnect);
    };
  }, []);

  const {
    piwik: { piwikRootUrl, siteId },
    SGWTWidgetConfiguration: { environment },
  } = getConfig();

  return (
    <ReduxProvider store={store}>
      <IntlProvider locale={lang} globalMessagesMap={globalMessagesMap}>
        <StrictMode>
          <SgwtWidgetContextProvider infrastructure="azure">
            <SgwtWebAnalytics
              siteId={siteId}
              baseUrl={piwikRootUrl}
              debug={environment === 'homologation'} // To show debug on homolo env
            />
            <ServiceLoader>
              <CrashModal />
              <App />
            </ServiceLoader>
          </SgwtWidgetContextProvider>
        </StrictMode>
      </IntlProvider>
    </ReduxProvider>
  );
};

// session ID is kept until the browser tab is closed
const getSessionId = () => {
  const sessionId = sessionStorage.getItem('sessionId');

  if (isDefined(sessionId)) {
    return sessionId;
  }

  const newSessionId = crypto.randomUUID();

  sessionStorage.setItem('sessionId', newSessionId);

  return newSessionId;
};
