import { TabIdProvider } from 'components/NavMenu';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../state';
import { selectors } from '../../../state/selectors';
import { TabItem } from './TabItem';

const TabBar = styled.ul.attrs({
  className: 'overflow-hidden nav d-flex flex-nowrap',
  role: 'tablist',
})`
  transition: margin-left 0.5s;
`;

export const TabList = () => {
  const tabIds = useAppSelector(selectors.getClientWorkspaceTabIds);
  const activeTab = useAppSelector(selectors.getClientWorkspaceActiveTab);

  const [offset, setOffset] = useState(0);
  const [maxOffset, setMaxOffset] = useState(0);

  const tabBarRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (tabBarRef.current !== null && tabBarRef.current.parentElement !== null) {
      const newMaxOffset = Math.min(
        0,
        tabBarRef.current.parentElement.clientWidth -
          Array.from(tabBarRef.current.children).reduce(
            (p, c) => p + c.clientWidth,
            64, // magic value which works well, don't ask why
          ),
      );

      if (offset === maxOffset || offset < newMaxOffset) {
        setOffset(newMaxOffset);
      }

      setMaxOffset(newMaxOffset);
    }
  }, [maxOffset, offset, tabIds]);

  useEffect(() => {
    if (activeTab !== null) {
      setOffset(maxOffset === 0 ? 0 : (maxOffset * tabIds.indexOf(activeTab)) / Math.max(1, tabIds.length - 1));
    }
  }, [activeTab, maxOffset, tabIds]);

  const onWheel = useCallback(
    (event: React.WheelEvent<HTMLElement>) => {
      // Firefox will have deltaMode = DOM_DELTA_LINE (1)
      const delta = event.deltaMode === WheelEvent.DOM_DELTA_PIXEL ? event.deltaY / 2 : event.deltaY * 20;
      setOffset(Math.min(0, Math.max(maxOffset, offset - delta)));
    },
    [offset, maxOffset],
  );

  return (
    <TabBar style={{ marginLeft: offset }} ref={tabBarRef} onWheel={onWheel}>
      {tabIds.map((tabId) => (
        <TabIdProvider value={tabId} key={tabId}>
          <TabItem showClose={tabIds.length > 1} />
        </TabIdProvider>
      ))}
    </TabBar>
  );
};
