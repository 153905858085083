import { getApiUrl } from '../bootstrap/sgmeConfiguration';
import { getFakeUser, sgwtConnect } from './connect';

// see src/utils/sgmeHttp.ts
// this methods are useful when webAPI is called without RxJS epics

export const ENDPOINT_URL = `${getApiUrl()}`;

export const getApi = (url: string) =>
  fetch(`${ENDPOINT_URL}${url}`, {
    method: 'GET',
    headers: getFetchHeaders(),
  });

export const postApi = <TBody>(url: string, body?: TBody) =>
  fetch(`${ENDPOINT_URL}${url}`, {
    method: 'POST',
    headers: { ...getFetchHeaders(), 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

export const deleteApi = (url: string) =>
  fetch(`${ENDPOINT_URL}${url}`, {
    method: 'DELETE',
    headers: { ...getFetchHeaders(), 'Content-Type': 'application/json' },
  });

const getFetchHeaders = () => ({
  Application: 'FX',
  ...fakeAuthenticationHeader(),
  Authorization: sgwtConnect.getAuthorizationHeader() as string,
});

const fakeAuthenticationHeader = (): HeadersInit => {
  const fakeUser = getFakeUser();

  return fakeUser !== undefined && window.sgmeConfiguration.useFakeSgConnect
    ? { 'X-Fake-Authentication': fakeUser }
    : {};
};
