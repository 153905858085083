import { Suspense } from 'react';
import { AppCrash } from './AppCrash';
import { LiveBlotterPanel } from './Blotter/LiveBlotterPanel';
import { QuickBlotterButton } from './Blotter/QuickBlotterButton';
import { ClientWorkspace } from './ClientWorkspace/ClientWorkspace';
import { Theme } from './ClientWorkspace/Theme';
import { MifidPanel } from './MifidPanel/MifidPanel';
import { NavMenu as WorkspaceBar } from './NavMenu';
import { Toasts } from './Toasts/Toasts';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';
import { HelpCenter } from './layout/HelpCenter';
import { UserHasAccess } from './share/RenderWhen';
import { lazyWithRetry } from './share/lazy';

const AppModals = lazyWithRetry(() => import('../components/Modals'));

export function App() {
  return (
    <AppCrash>
      <Theme>
        <div className="d-flex flex-column vh-100">
          <Header />
          <WorkspaceBar />
          <Suspense
            fallback={
              <div className="h-100 w-100 d-flex justify-content-around align-items-center">
                <div className="spinner spinner-xl" />
              </div>
            }
          >
            <div className="d-flex flex-grow-1 position-relative overflow-auto">
              <ClientWorkspace />
              <MifidPanel />
            </div>
            <UserHasAccess>
              <div className="position-relative">
                <QuickBlotterButton />
              </div>
              <LiveBlotterPanel />
            </UserHasAccess>
          </Suspense>
          <Footer />
          <Suspense fallback={null}>
            <AppModals />
            <Toasts />
          </Suspense>
        </div>
        <HelpCenter />
      </Theme>
    </AppCrash>
  );
}
